import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../components/Layouts/layout";
import css from "./index.module.scss";

// const roundUpHour = (date) => {
// 	date.setHours(date.getHours() + 1, 0,0,0);
// 	return date.getTime();
// }

const IndexPage = ({ data }) => {
  // const [ priceTab, setPriceTab ] = useState(null);
  // const [ actualPrice, setActualPrice ] = useState(null);
  // const priceIntervals = [0,7,20];
  // let timeRemaining = 1;

  // const changePriceZone = timeRemaining => setTimeout(() => {
  // 	let d = new Date();

  // 	// restart timer
  // 	timeRemaining = roundUpHour(new Date()) - d.getTime();
  // 	console.log((timeRemaining /3600000 ) * 60, changePriceZone);
  // 	changePriceZone(timeRemaining);

  // 	// set proper pricing tab
  // 	priceIntervals.some((e,i) => {
  // 		if(e <= d.getHours()) {
  // 			setPriceTab(i);
  // 			return true
  // 		}
  // 	});

  // }, timeRemaining + 500)
  // const changePriceZone = delay => setTimeout(() => {
  // 	let d = new Date();
  // 	// restart timer
  // 	timeRemaining = roundUpHour(new Date()) - d.getTime();
  // 	changePriceZone(timeRemaining);

  // 	// set proper pricing tab
  // 	priceIntervals.reverse().some((e,i) => {
  // 		console.log(e);
  // 		if(e <= 9) {
  // 			setPriceTab(i);
  // 			setActualPrice(i);
  // 			return true
  // 		}
  // 	});
  // }, delay)

  // useEffect(() => {
  // 	// set actual price tab
  // 	// changePriceZone(timeRemaining);
  // 	console.log('now' + new Date())

  // 	changePriceZone(timeRemaining);
  // }, [])

  return (
    <Layout>
      <div id="hero" className="hero">
        <Img
          fluid={data.img2.childImageSharp.fluid}
          alt="Background"
          className="opacity-9"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
          }}
        />
        <div className="container">
          <div className="row align-items-center pt-3">
            <div className="col-lg-6 mb-sm-5">
              {/* <p
								className="text-primary font-weight-bold mb-1 appear-animation animated fadeInUpShorter appear-animation-visible"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-duration="750"
								style={{ animationDelay: '100ms' }}
							>
								Co je zamčené.. otevřeme<br />Co je otevřené.. zabezpečíme.
							</p> */}
              <h1 className="font-weight-bold text-xs-6 text-sm-12 line-height-2 mb-3  text-white">
                Otevření zabouchnutých a zamčených dveří
                <span className="d-inline-block text-primary highlighted-word highlighted-word-rotate highlighted-word-animation-1 alternative-font-3 font-weight-bold text-1 ml-2">
                  24 / 7
                </span>
              </h1>
              <p className="custom-font-size-1  text-white">
                Profesionální a spolehlivé zámečnictví v Praze.
                <br /> Pracujeme{" "}
                <strong className="text-uppercase">levně</strong>,{" "}
                <strong className="text-uppercase">kvalitně</strong>,{" "}
                <strong className="text-uppercase">rychle</strong>.<br />{" "}
                Spolupracujeme s řadou firem, státních institucí a{" "}
                <strong>Policií ČR</strong>.{" "}
              </p>
            </div>
            <div className="col-lg-5 offset-lg-1 ">
              <div className="row align-items-stretch">
                <div className="d-flex col-sm-12 col-lg-6 mb-4">
                  <AnchorLink
                    href="#sluzby"
                    className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
                  >
                    <div className="card-body">
                      <h2 className="h4 card-title mb-1 text-4 font-weight-bold">
                        OTEVŘENÍ ZABOUCHNUTÝCH DVEŘÍ
                      </h2>
                      <p className="card-text">Dveře otvíráme bez poškození</p>
                    </div>
                  </AnchorLink>
                </div>
                <div className="d-flex col-sm-12 col-lg-6 mb-4">
                  <AnchorLink
                    href="#sluzby"
                    className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
                  >
                    <div className="card-body">
                      <h2 className="h4 card-title mb-1 text-4 font-weight-bold">
                        OTEVŘENÍ ZAMČENÝCH DVEŘÍ
                      </h2>
                      <p className="card-text">Pro vlastníky i nájemce</p>
                    </div>
                  </AnchorLink>
                </div>
                <div className="d-flex col-sm-12 col-lg-6 mb-4">
                  <AnchorLink
                    href="#sluzby"
                    className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
                  >
                    <div className="card-body">
                      <h2 className="h4 card-title mb-1 text-4 font-weight-bold">
                        KOMPLETNÍ SLUŽBY
                      </h2>
                      <p className="card-text">
                        Zalomený klíč, sjednocení vložek a další služby...
                      </p>
                    </div>
                  </AnchorLink>
                </div>
                <div className="d-flex col-sm-12 col-lg-6 mb-4">
                  <AnchorLink
                    href="#cenik"
                    className="card flex-grow-1 border-radius-1 bg-color-light box-shadow-1 bg-color-hover-primary transition-2ms card-text-color-hover-light"
                  >
                    <div className="card-body">
                      <h2 className="h4 card-title mb-1 text-4 font-weight-bold">
                        {/* FIXNÍ CENY NONSTOP */}
                        FÉROVÉ CENY NONSTOP
                      </h2>
                      <p className="card-text">
                        Služby za přijatelné ceny - 24 / 7
                      </p>
                    </div>
                  </AnchorLink>
                </div>
              </div>
            </div>
            <div className="myBar col-md-8 alternative-font-4 col-lg-6 col-xl-5 custom-header-bar py-4 pr-5  z-index-3 animated maskRight ">
              <div className="heroClaim fadeInRightShorter ">
                {/* <div className="star-rating-wrap d-flex align-items-center justify-content-center position-relative text-warning text-5 py-2 mb-2">
									<i className="fas fa-star" />
									<i className="fas fa-star ml-1" />
									<i className="fas fa-star ml-1" />
									<i className="fas fa-star ml-1" />
									<i className="fas fa-star ml-1" />
								</div> */}

                <div className="position-relative text-center font-weight-bold text-7 line-height-2 text-white mb-0">
                  Co je zamčené, otevřeme.
                  <br />
                  Co je otevřené, zabezpečíme.
                </div>
                {/* <p className="position-relative text-center text-white font-weight-normal mb-1">
									* Přidejte se mezi naše spokojené klienty
								</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="uvod" className="container pt-5">
        <div className="row mb-5 pb-lg-3 counters">
          <div className="col-lg-10 text-center offset-lg-1">
            <h1 className="font-weight-bold text-9 mb-0">
              Zámkařství Praha s.r.o.
              <br /> Zámkařské a zámečnické práce
            </h1>
            <p className="sub-title text-primary text-4 font-weight-semibold heading heading-border heading-middle-border heading-middle-border-center mt-2 mb-4 ">
              <span className="headingBorder">
                VAŠE CESTA K{" "}
                <span className="highlighted-word alternative-font-4 font-weight-semibold line-height-2 pb-2">
                  PERFEKTNÍMU ZABEZPEČENÍ
                </span>
              </span>
            </p>
            {/* <p className="text-1rem text-color-default negative-ls-05 pb-4 mb-5 ">
							Zámkařství Praha s.r.o Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda id at
							asperiores sit est, voluptate atque? Ullam facere aut commodi voluptatibus omnis! Cum, commodi
							alias quidem non esse recusandae. Consequuntur.
						</p> */}
            <Img
              fluid={data.img1.childImageSharp.fluid}
              alt="Zámkařské práce"
              className="decorationImg"
            />
          </div>
        </div>
      </div>
      <section id="sluzby">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 align-self-center p-static text-center">
              <h2 className="text-10 mb-0">
                Zámkařské & Zámečnické <strong>Služby</strong>
              </h2>
              <span className="sub-title">S čím Vám můžeme pomoci</span>

              <div className="feature-box align-items-center justify-content-center pt-2">
                <div className="d-inline-flex">
                  <a href={"tel:+420" + data.site.siteMetadata.tel}>
                    <i
                      className="fab fa-whatsapp text-7 p-relative"
                      style={{ top: "-2px" }}
                    />
                  </a>
                </div>
                <div className="d-inline-flex">
                  <p className="pb-0 ml-2 mb-2 font-weight-semibold line-height-5 text-3">
                    <a href={"tel:+420" + data.site.siteMetadata.tel}>
                      (+420) {data.site.siteMetadata.tel}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <h4 className="text-uppercase">Zamčené a zabouchnuté dveře</h4>
              <ul className="list list-icons">
                <li>
                  <i className="fas fa-check" /> Otevření zabouchnutých dveří
                  bez poškození
                </li>
                <li>
                  <i className="fas fa-check" /> 24 / 7
                </li>
                <li>
                  <i className="fas fa-check" /> Rychlé a fér jednání
                </li>
              </ul>
              <p>
                <strong>Vlastník</strong>: Požadujeme doklad totožnosti +
                nahlédnutí do katastru nemovitostí. <br />
                <strong>Nájemce</strong>: Požadujeme nájemní slouvu + asistenci
                vlastníka / policie ČR.{" "}
                <AnchorLink href="#cenik">
                  <strong>
                    Aktuální ceny <i className="fas fa-chevron-down"></i>
                  </strong>
                </AnchorLink>
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <h4 className="text-uppercase">Vložky a zámky</h4>
              <ul className="list list-icons">
                <li>
                  <i className="fas fa-check" /> Výměna standardních i lištových
                  zámků
                </li>
                <li>
                  <i className="fas fa-check" /> Sjednocení vložek
                </li>
                <li>
                  <i className="fas fa-check" /> Dle aktuální úrovně, nebo
                  žádosti na zvýšení třídy zabezpečení
                </li>
              </ul>
              <p>
                Kvalita zabezpečení dveří vložkou je často nejslabším článkem na
                úrovni zabezpečení objektu. Pomůžeme Vám ji zvýšit.{" "}
                <AnchorLink href="#cenik">
                  <strong>
                    Aktuální ceny <i className="fas fa-chevron-down"></i>
                  </strong>
                </AnchorLink>
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <h4 className="text-uppercase">Instalace a výměna kování</h4>
              <ul className="list list-icons">
                <li>
                  <i className="fas fa-check" /> Výměna starého, či instalace
                  nového kování
                </li>
                <li>
                  <i className="fas fa-check" /> Dle třídy zabezpečení
                  stávajících dveří
                </li>
                <li>
                  <i className="fas fa-check" /> Dle požadavku na zvýšení třídy
                  zabezpečení
                </li>
              </ul>
              <p>
                <AnchorLink href="#cenik">
                  <strong>
                    Aktuální ceny <i className="fas fa-chevron-down"></i>
                  </strong>
                </AnchorLink>
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <h4 className="text-uppercase">Vyjmutí zalomeného klíče</h4>
              <ul className="list list-icons">
                <li>
                  <i className="fas fa-check" /> Poradíme si s libovolným zámkem
                </li>
                <li>
                  <i className="fas fa-check" /> Klíč vyndáme bez poškození
                  zámku
                </li>
              </ul>
              <p>
                Zalomení klíče je velmi častý problém, se kterým se potýkáme
                denně.{" "}
                <AnchorLink href="#cenik">
                  <strong>
                    Aktuální ceny <i className="fas fa-chevron-down"></i>
                  </strong>
                </AnchorLink>
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <h4 className="text-uppercase">Systémy generálního klíče</h4>
              <ul className="list list-icons">
                <li>
                  <i className="fas fa-check" /> Na míru dle vašich požadavků
                </li>
                <li>
                  <i className="fas fa-check" /> Generální, skupinové i vlastní
                  klíče
                </li>
              </ul>
              <p>
                Systém generálního klíče umožňuje řídit přístupy do jednotlivých
                dveří pro jednotlivce i skupiny. Generální klíč odemkne všechny
                dveře, ostatní dle nastavení systému.{" "}
                <AnchorLink href="#cenik">
                  <strong>
                    Aktuální ceny <i className="fas fa-chevron-down"></i>
                  </strong>
                </AnchorLink>
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
              <h4 className="text-uppercase">Další služby</h4>
              <p>
                Poradíme si i s <strong>trezory</strong>,{" "}
                <strong>poštovními schránkami</strong>, nebo{" "}
                <strong>visacími zámky</strong>{" "}
                <AnchorLink href="#cenik">
                  <strong>
                    Aktuální ceny <i className="fas fa-chevron-down"></i>
                  </strong>
                </AnchorLink>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="reference"
        className="section bg-color-grey-scale-1 section-height-3 border-0 m-0 pb-0"
      >
        <div className="container pb-2">
          <div className="row">
            <div className="col-lg-6 text-center text-md-left mb-5 mb-lg-0">
              <h2 className="text-color-dark font-weight-normal text-6 mb-2">
                Naše{" "}
                <strong className="font-weight-extra-bold">Reference</strong>
              </h2>
              <p className="lead">
                Každý zákazník je pro nás důležitý a jsme rádi, pokud je s
                našimi službami spokojen
              </p>
              <div className="row justify-content-center mt-2">
                <div className="col-5 text-center col-md-3">
                  <Img
                    fluid={data.logo1.childImageSharp.fluid}
                    alt="CISA logo"
                    className="img-fluid hover-effect-3"
                  />
                </div>
                <div className="col-5 text-center col-md-3 my-3 my-md-0">
                  <Img
                    fluid={data.logo2.childImageSharp.fluid}
                    alt="EVVA logo"
                    className="img-fluid hover-effect-3"
                  />
                </div>
                <div className="col-5 text-center col-md-3">
                  <Img
                    fluid={data.logo3.childImageSharp.fluid}
                    alt="FAB logo"
                    className="img-fluid hover-effect-3"
                  />
                </div>
                <div className="col-5 text-center col-md-3">
                  <Img
                    fluid={data.logo4.childImageSharp.fluid}
                    alt="FAB logo"
                    className="img-fluid hover-effect-3"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-quotes-dark testimonial-remove-right-quote pl-md-4 mb-4">
                <div className="testimonial-author">
                  <p className="filled-stars d-flex justify-content-center mb-3">
                    <span className="star  text-7 text-primary">
                      <i className="fas fa-star" />
                    </span>
                    <span className="star  text-7 text-primary">
                      <i className="fas fa-star" />
                    </span>
                    <span className="star  text-7 text-primary">
                      <i className="fas fa-star" />
                    </span>
                    <span className="star  text-7 text-primary">
                      <i className="fas fa-star" />
                    </span>
                    <span className="star  text-7 text-primary">
                      <i className="fas fa-star" />
                    </span>
                  </p>
                </div>
                <blockquote>
                  <p className="text-color-dark text-4 line-height-5 mb-0">
                    Děkuji za rychlou a profesionální práci! Stál jsem před
                    bytem a nevěděl jak dál. Pán dorazil za chvíli, okoukl
                    situaci a seznámil mě s ceníkem. Pak už to byla rychlovka."
                  </p>
                </blockquote>
                <div className="testimonial-author">
                  <p>
                    <strong className="font-weight-extra-bold text-2">
                      Petr D.
                    </strong>
                    <span>Praha 8</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-primary border-top-0 mb-0 py-5">
          <div className="container">
            <div className="row counters counters-sm counters-text-light">
              <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                <div className="counter">
                  <strong data-to="14000" data-append="+">
                    14000+
                  </strong>
                  <label className="opacity-5 text-4 mt-0 text-white">
                    Spokojených klientů
                  </label>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                <div className="counter">
                  <strong data-to="4200" data-append="+">
                    4200+
                  </strong>
                  <label className="opacity-5 text-4 mt-0 text-white">
                    Otevřených dveří
                  </label>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
                <div className="counter">
                  <strong data-to="20" data-append="min">
                    20min
                  </strong>
                  <label className="opacity-5 text-4 mt-0 text-white">
                    Průměrný čas dojezdu{" "}
                  </label>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="counter">
                  <strong data-to="24" data-append="h">
                    24h
                  </strong>
                  <label className="opacity-5 text-4 mt-0 text-white">
                    Denně
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cenik">
        <div className="container pt-5">
          <div className="row mb-5">
            <div className="col-md-12 align-self-center p-static text-center">
              <h2 className="text-10 mb-0">
                <strong>Ceník</strong> služeb
              </h2>
              <span className="sub-title">
                Všechny ceny jsou uvedeny bez DPH 15% / 21%
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="text-center">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th>Služby</th>
                          <th className="text-right">Cena od</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Zabouchnuté dveře obyčejné</td>
                          <td className="text-right">350 Kč</td>
                        </tr>
                        <tr>
                          <td>Zabouchnuté dveře s bezpečnostním kováním</td>
                          <td className="text-right">500 Kč</td>
                        </tr>
                        <tr>
                          <td>Zabouchnuté dveře bezpečnostní, plastové</td>
                          <td className="text-right">600 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčené dveře obyčejné</td>
                          <td className="text-right">800 Kč</td>
                        </tr>
                        <tr>
                          <td>
                            Zamčené dveře bezpečnostní, vložka proti odvrtání
                          </td>
                          <td className="text-right">1 200 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčené dveře bezpečnostní</td>
                          <td className="text-right">1 400 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčený přídavný zámek</td>
                          <td className="text-right">800 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčený přídavný zámek bezpečnostní</td>
                          <td className="text-right">1 200 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčený dozický a tvarovaný zámek</td>
                          <td className="text-right">600 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčený poštovní, kancelářský, visací zámek</td>
                          <td className="text-right">400 Kč</td>
                        </tr>
                        <tr>
                          <td>Zamčený trezorový zámek</td>
                          <td className="text-right">3 500 Kč</td>
                        </tr>
                        <tr>
                          <td>Zabouchnuté dveře, 3x střelka</td>
                          <td className="text-right">1 000 Kč</td>
                        </tr>
                        <tr>
                          <td>Instalace bezpečnostního kování R1, R3</td>
                          <td className="text-right">800 Kč</td>
                        </tr>
                        <tr>
                          <td>Instalace přídavného zámku 1572, OS2</td>
                          <td className="text-right">800 Kč</td>
                        </tr>
                        <tr>
                          <td>Ostatní zámečnické služby</td>
                          <td className="text-right">dle dohody</td>
                        </tr>
                        <tr>
                          <td>Odstranění vložky, rozlomení</td>
                          <td className="text-right">500 Kč</td>
                        </tr>
                        <tr>
                          <td>Vyjmutí zalomeného klíče</td>
                          <td className="text-right">500 Kč</td>
                        </tr>
                        <tr>
                          <td>Montáž, demontáž kování, vložky, zámku</td>
                          <td className="text-right">300 Kč</td>
                        </tr>
                        <tr>
                          <td>Zablokovaná střelka, zámek, rozvory</td>
                          <td className="text-right">1 500 Kč</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12 col-md-6">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th>Režie</th>
                          <th className="text-right">Cena</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Výjezd technika</td>
                          <td className="text-right">600 Kč</td>
                        </tr>
                        <tr>
                          <td>Zpětný výjezd a druhá cesta technika</td>
                          <td className="text-right">200 Kč</td>
                        </tr>
                        <tr>
                          <td>Marný výjezd (bez zásahu)</td>
                          <td className="text-right">600 Kč</td>
                        </tr>
                        <tr>
                          <td>Cestovné mimo Prahu do 10 km</td>
                          <td className="text-right">+ 200 Kč</td>
                        </tr>
                        <tr>
                          <td>Cestovné mimo Prahu nad 10 km</td>
                          <td className="text-right">dle dohody</td>
                        </tr>
                        <tr>
                          <td>Hodinová práce</td>
                          <td className="text-right">600 Kč</td>
                        </tr>
                        <tr>
                          <td>Spotřební materiál</td>
                          <td className="text-right">dle spotřeby</td>
                        </tr>
                        <tr>
                          <td>Doba čekání nad 15 min</td>
                          <td className="text-right">+ 200 Kč</td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th>Příplatky</th>
                          <th className="text-right">Cena</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Výjezd od 20:00 - 24:00</td>
                          <td className="text-right">+ 50% ceny</td>
                        </tr>
                        <tr>
                          <td>Výjezd od 24:00 - 07:00</td>
                          <td className="text-right">+ 100% z ceny</td>
                        </tr>
                        <tr>
                          <td>Výjezd v sobotu a neděli</td>
                          <td className="text-right">+ 50% z ceny</td>
                        </tr>
                        <tr>
                          <td>Práce ve svátek</td>
                          <td className="text-right">+ 100% z ceny</td>
                        </tr>
                        <tr>
                          <td>
                            Práce při ztížených podmínkách a práce do kovu
                          </td>
                          <td className="text-right">+ 50% z ceny</td>
                        </tr>
                        <tr>
                          <td>Oprava po vloupání</td>
                          <td className="text-right">dle dohody</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* // * old pricing */}
          {/* <div className="row">
						<div className="col">
							<div className="tabs tabs-bottom tabs-center tabs-simple">
								<ul className="nav nav-tabs">
									<li className={priceTab === 0 ? 'nav-item active' : 'nav-item'}>
										<button className="nav-link" data-toggle="tab" onClick={() => setPriceTab(0)}>
											00:00 - 07:00
										</button>
									</li>
									<li className={priceTab === 1 ? 'nav-item active' : 'nav-item'}>
										<button className="nav-link" onClick={() => setPriceTab(1)} data-toggle="tab">
											07:00 - 20:00
										</button>
									</li>
									<li className={priceTab === 2 ? 'nav-item active' : 'nav-item'}>
										<button
											className="nav-link"
											onClick={() => setPriceTab(2)}
											data-toggle="tab"
										>
											20:00 - 00:00
										</button>
									</li>
								</ul>
								<div className="tab-content">
									<div className={priceTab === 0 ? 'tab-pane active' : 'tab-pane'} id="cenyrano">
										<div className="text-center">
											<h4>Ranní tarif {actualPrice === 0 ? <><br /><small>( aktuální )</small></> : null}</h4>
											<div className="row">
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Fixní cena</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Zabouchnuté dveře</td>
																<td>ANO</td>
																<td>1800 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté plastové, bezpečnostní</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno tvarový zámek</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno standart, přídavný zámek</td>
																<td>ANO</td>
																<td>2300 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno dozický zámek</td>
																<td>ANO</td>
																<td>2300 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno vložka proti odvrtání, přídavný zámek</td>
																<td>ANO</td>
																<td>2600 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno bezpečnostní kování, bezpečnostní dveře</td>
																<td>ANO</td>
																<td>3000 Kč</td>
															</tr>
															<tr>
																<td>Trezorový systém</td>
																<td>ANO</td>
																<td>3500 Kč</td>
															</tr>
															<tr>
																<td>Zamčený visací, poštovní, nebo skříňkový zámek</td>
																<td>ANO</td>
																<td>1400 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté dveře 3x střelka</td>
																<td>ANO</td>
																<td>2500 Kč</td>
															</tr>
															<tr>
																<td>Prasklá střelka</td>
																<td>ANO</td>
																<td>3000 Kč</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>Fixní cena je včetně všech režií a výjezdu </em>
													</p>
												</div>
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Výjezd</td>
																<td>800 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy do 10 km 200 Kč </td>
																<td>400 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy nad 10 km</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Odstranění vložky bez klíče</td>
																<td>800 Kč *</td>
															</tr>
															<tr>
																<td>Vyjmutí zalomeného klíče</td>
																<td>1000 Kč *</td>
															</tr>
															<tr>
																<td>Výměna zámku, vložky a přídavného zámku</td>
																<td>400 Kč *</td>
															</tr>
															<tr>
																<td>Montáž, demontáž, kování</td>
																<td>400 Kč *</td>
															</tr>
															<tr>
																<td>Marný výjezd (bez zásahu)</td>
																<td>700 Kč</td>
															</tr>
															<tr>
																<td>Otevření trezoru</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Hodina práce</td>
																<td>700 Kč</td>
															</tr>
															<tr>
																<td>Zaseklá závora, rozvory, trny</td>
																<td>Dle dohody</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>* nutno započítat výjezd technika </em>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className={priceTab === 1 ? 'tab-pane active' : 'tab-pane'} id="cenyden">
										<div className="text-center">
											<h4>Denní tarif {actualPrice === 1 ? <small>aktuální</small> : null}</h4>
											<div className="row">
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Fixní cena</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Zabouchnuté dveře</td>
																<td>ANO</td>
																<td>1000 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté plastové, bezpečnostní</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno tvarový zámek</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno standart, přídavný zámek</td>
																<td>ANO</td>
																<td>1500 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno dozický zámek</td>
																<td>ANO</td>
																<td>1500 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno vložka proti odvrtání, přídavný zámek</td>
																<td>ANO</td>
																<td>1800 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno bezpečnostní kování, bezpečnostní dveře</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Trezorový systém</td>
																<td>ANO</td>
																<td>3500 Kč</td>
															</tr>
															<tr>
																<td>Zamčený visací, poštovní, nebo skříňkový zámek</td>
																<td>ANO</td>
																<td>1000 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté dveře 3x střelka</td>
																<td>ANO</td>
																<td>1500 Kč</td>
															</tr>
															<tr>
																<td>Prasklá střelka</td>
																<td>ANO</td>
																<td>1800 Kč</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>Fixní cena je včetně všech režií a výjezdu </em>
													</p>
												</div>
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Výjezd</td>
																<td>500 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy do 10 km 200 Kč </td>
																<td>200 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy nad 10 km</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Odstranění vložky bez klíče</td>
																<td>500 Kč *</td>
															</tr>
															<tr>
																<td>Vyjmutí zalomeného klíče</td>
																<td>500 Kč *</td>
															</tr>
															<tr>
																<td>Výměna zámku, vložky a přídavného zámku</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Montáž, demontáž, kování</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Marný výjezd (bez zásahu)</td>
																<td>500 Kč</td>
															</tr>
															<tr>
																<td>Otevření trezoru</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Hodina práce</td>
																<td>500 Kč</td>
															</tr>
															<tr>
																<td>Zaseklá závora, rozvory, trny</td>
																<td>Dle dohody</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>* nutno započítat výjezd technika </em>
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className={priceTab === 2 ? 'tab-pane active' : 'tab-pane'} id="cenyvecer">
										<div className="text-center">
											<h4>Večerní tarif {actualPrice === 2 ? <small>aktuální</small> : null}</h4>
											<div className="row">
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Fixní cena</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
														<tr>
																<td>Zabouchnuté dveře</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté plastové, bezpečnostní</td>
																<td>ANO</td>
																<td>1400 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno tvarový zámek</td>
																<td>ANO</td>
																<td>1400 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno standart, přídavný zámek</td>
																<td>ANO</td>
																<td>1700 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno dozický zámek</td>
																<td>ANO</td>
																<td>1700 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno vložka proti odvrtání, přídavný zámek</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Zamčeno bezpečnostní kování, bezpečnostní dveře</td>
																<td>ANO</td>
																<td>2400 Kč</td>
															</tr>
															<tr>
																<td>Trezorový systém</td>
																<td>ANO</td>
																<td>3500 Kč</td>
															</tr>
															<tr>
																<td>Zamčený visací, poštovní, nebo skříňkový zámek</td>
																<td>ANO</td>
																<td>1200 Kč</td>
															</tr>
															<tr>
																<td>Zabouchnuté dveře 3x střelka</td>
																<td>ANO</td>
																<td>2000 Kč</td>
															</tr>
															<tr>
																<td>Prasklá střelka</td>
																<td>ANO</td>
																<td>2400 Kč</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>Fixní cena je včetně všech režií a výjezdu </em>
													</p>
												</div>
												<div className="col-12 col-md-6">
													<table className="table table-sm">
														<thead>
															<tr>
																<th>Služba</th>
																<th>Cena</th>
															</tr>
														</thead>
														<tbody>
														<tr>
																<td>Výjezd</td>
																<td>600 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy do 10 km 200 Kč </td>
																<td>300 Kč</td>
															</tr>
															<tr>
																<td>Výjezd z Prahy nad 10 km</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Odstranění vložky bez klíče</td>
																<td>700 Kč *</td>
															</tr>
															<tr>
																<td>Vyjmutí zalomeného klíče</td>
																<td>700 Kč *</td>
															</tr>
															<tr>
																<td>Výměna zámku, vložky a přídavného zámku</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Montáž, demontáž, kování</td>
																<td>300 Kč *</td>
															</tr>
															<tr>
																<td>Marný výjezd (bez zásahu)</td>
																<td>600 Kč</td>
															</tr>
															<tr>
																<td>Otevření trezoru</td>
																<td>Dle dohody</td>
															</tr>
															<tr>
																<td>Hodina práce</td>
																<td>600 Kč</td>
															</tr>
															<tr>
																<td>Zaseklá závora, rozvory, trny</td>
																<td>Dle dohody</td>
															</tr>
														</tbody>
													</table>
													<p>
														<em>* nutno započítat výjezd technika </em>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
        </div>
      </section>
      <AnchorLink href="#header" className="scroll-to-top visible">
        <i className="fas fa-chevron-up"></i>
      </AnchorLink>
      <a href="tel:00420720545458" className={css.banner}>
        <i className="fab fa-whatsapp" /> Volejte 720 54 54 58
      </a>
    </Layout>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    img1: file(relativePath: { eq: "img.png" }) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo1: file(relativePath: { eq: "cisa_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo2: file(relativePath: { eq: "evva_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo3: file(relativePath: { eq: "FAB_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo4: file(relativePath: { eq: "mult-lock_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        email
        tel
      }
    }
  }
`;
